import dayjs from 'dayjs'
import elasticlunr, { Index } from 'elasticlunr'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { groupBy, keyBy } from 'lodash'
import slugify from 'slugify'
import Carousel from '../components/carousel'
import Image from '../components/image'
import DateFilter from '../components/date-filter'
import EventList from '../components/event-list'
import EventsByDate from '../components/events-by-date'
import SearchFilter from '../components/search-filter'
import SelectFilter, { SelectFilterOption } from '../components/select-filter'
import SocialMediaSharing from '../components/social-media-sharing'
import Sponsors from '../components/sponsors'
import Tab from '../components/tab'
import Tabs from '../components/tabs'
import TagFilter, { TagFilterOption } from '../components/tag-filter'
import { useIsMobile } from '../hooks/screen-size'
import { useVeeziWebSessionsAll } from '../hooks/veezi-web-sessions'
import styles from './theme.module.css'
import { filterSessionsByDate, containsAll } from '../util/events'
import { flatten } from '../util/array'
import SEO from '../components/seo'

const themeCarouselSlides = (coverImages, title, onPlay, onPause) =>
  (coverImages || []).map(i => {
    const imageSrc =
      i.image && i.image.childImageSharp ? i.image.childImageSharp.fluid : null

    return (
      <article className={styles.coverImageContainer}>
        <Image fluid={imageSrc} className={styles.coverImage} />
      </article>
    )
  })

const THEME_TYPES = {
  showcase: 'Showcase',
  'film-festival': 'Film Festival',
}

export const ThemeTemplate = ({
  theme,
  events,
  categories = [],
  venues,
  location: { hash },
  locations,
  searchIndex,
}) => {
  const tabs = ['locations']
  const [isSlidePaused, setIsSlidePaused] = useState(false)
  const isMobile = useIsMobile()
  const onVideoPlay = useRef()
  const onVideoPause = useRef()

  onVideoPlay.current = () => {
    setIsSlidePaused(true)
  }

  onVideoPause.current = () => {
    setIsSlidePaused(false)
  }

  const slides = themeCarouselSlides(
    theme.coverImages,
    theme.title,
    onVideoPlay.current,
    onVideoPause.current
  )

  const searchIcon = (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.427 15.427a8.5 8.5 0 112-2l5.585 5.585c.55.55.546 1.431 0 1.976l-.023.023a1.398 1.398 0 01-1.976 0l-5.585-5.585-.001.001zM8.5 15a6.5 6.5 0 100-13 6.5 6.5 0 000 13z"
        fill="#FFF"
      />
    </svg>
  )
  const themeStartDate = useRef(
    theme && theme.startDate ? dayjs(theme.startDate) : dayjs()
  )
  const veeziWebSessions = useVeeziWebSessionsAll()
  const [tab, setTab] = useState('categories')

  const [selectedLocation, setSelectedLocation] = useState('golden-mile-tower')
  const onTabClick = useCallback(tab => {
    window.location = '#' + tab
    setTab('locations')
  }, [])

  const onLocationClick = useCallback(location => {
    window.location.hash = '#locations-' + location
    setSelectedLocation(location)
  }, [])

  const groupByLocations = groupBy(veeziWebSessions, s => {
    const location = locations.find(l => l.screens.includes(s.ScreenId))
    if (location) return slugify(location.title).toLowerCase()
    return 'golden-mile-tower'
  })

  const [selectedCategory, setSelectedCategory] = useState('all')
  const [selectedAccessibilities, setSelectedAccessibilities] = useState({
    wheelchair: true,
  })
  const [searchKeyword, setSearchKeyword] = useState('')
  const [selectedDate, setSelectedDate] = useState(themeStartDate.current)
  const [dateRange, setDateRange] = useState([
    dayjs(selectedDate).startOf('week'),
    dayjs(selectedDate)
      .startOf('week')
      .add(6, 'days'),
  ])

  useEffect(() => {
    // const defaultTab = 'categories'
    // const resetChildren = () => {
    //   setSelectedLocation('golden-mile-tower')
    //   setSelectedCategory('all')
    // }
    if (hash && hash.includes('-')) {
      const t = hash.substring(1, hash.indexOf('-'))
      const id = hash.substring(hash.indexOf('-') + 1, hash.length)
      if (tabs.includes(t)) {
        if (tab !== t) setTab(t)
        else if (t === 'locations') {
          setSelectedLocation(id)
        }
      // } else {
      //   setTab(defaultTab)
      //   resetChildren()
      }
    } else {
      // const t =
      //   hash && tabs.includes(hash.substring(1, hash.length))
      //     ? hash.substring(1, hash.length)
      //     : defaultTab
      // if (tab !== t) setTab(t)
      // resetChildren()
    }
  }, [hash, tabs, tab, categories, events])

  events = useMemo(
    () =>
      events.map(e => {
        e._sessions = e.veeziFilmId
          ? veeziWebSessions.filter(s => e.veeziFilmId === s.FilmId)
          : []

        if (e.eventTypes.includes('film')) {
          e.accessibility = veeziWebSessions
            .filter(s => e.veeziFilmId === s.FilmId)
            .map(s => s.ScreenId)
            .reduce((a, s) => {
              const v = venues.find(
                v =>
                  v.screens &&
                  v.screens.findIndex(sc => sc.veeziScreenId === s) > -1
              )
              if (v && v.accessibility && v.accessibility.length)
                a.push(...v.accessibility)
              return a
            }, [])
          e.accessibility = Array.from(new Set(e.accessibility))
        } else if (e.eventTypes.includes('event')) {
          e.accessibility = (e.venue && e.venue.accessibility) || []
        }
        return e
      }),
    [events, veeziWebSessions, venues]
  )

  const eventsByCategory = useMemo(
    () =>
      events.reduce((cats, f) => {
        ; (f.categories || []).forEach(
          c => (cats[c.id] = [...(cats[c.id] || []), f])
        )

        return cats
      }, {}),
    [events]
  )

  const categoriesToShow = useMemo(
    () =>
      (categories || []).filter(c =>
        (Object.keys(eventsByCategory) || []).includes(c.id)
      ),
    [eventsByCategory, categories]
  )

  const calculateEventDates = events =>
    Array.from(
      events.reduce((dates, e) => {
        if (e.eventTypes.includes('film') || e._sessions.length) {
          e._sessions.forEach(s =>
            dates.add(
              dayjs(s.PreShowStartTime)
                .startOf('day')
                .toString()
            )
          )
        } else if (e.eventTypes.includes('event') && e.startDate) {
          dates.add(
            dayjs(e.startDate)
              .startOf('day')
              .toString()
          )
        }
        return dates
      }, new Set())
    ).map(ds => dayjs(ds).toDate())

  const eventDates = useRef(calculateEventDates(events))

  useEffect(() => {
    eventDates.current = calculateEventDates(events)
    const currentDate =
      eventDates.current
        .sort((a, b) => a - b)
        .find(d => dayjs().isSameOrBefore(d)) || dayjs()
    themeStartDate.current = currentDate
    setSelectedDate(currentDate)
    setDateRange([
      dayjs(currentDate).startOf('week'),
      dayjs(currentDate)
        .startOf('week')
        .add(6, 'days'),
    ])
  }, [events])

  const eventsForDate = useMemo(
    () =>
      events.filter(e => {
        if (e.eventTypes.includes('film') || e._sessions.length) {
          return (
            e._sessions.length &&
            !!filterSessionsByDate({
              selectedDate,
              veeziWebSessions: e._sessions,
              startDate: dateRange[0],
              endDate: dateRange[1],
            }).length
          )
        } else if (e.eventTypes.includes('event') && e.startDate) {
          const startDate = dayjs(e.startDate)
          if (selectedDate === 'all') {
            if (startDate.isBetween(dateRange[0], dateRange[1], 'day')) {
              return true
            }
          } else {
            if (startDate.isSame(selectedDate, 'day')) {
              return true
            }
          }
          return false
        } else return false
      }),
    [events, dateRange, selectedDate]
  )

  let eventsToShow
  if (tab === 'categories') {
    eventsToShow =
      selectedCategory === 'all' ? events : eventsByCategory[selectedCategory]
  } else if (tab === 'dates') {
    eventsToShow = eventsForDate
  } else if (tab === 'accessibility') {
    const filters = Object.keys(selectedAccessibilities).reduce((f, k) => {
      if (selectedAccessibilities[k]) f.push(k)
      return f
    }, [])

    eventsToShow = !filters.length
      ? events
      : events.filter(
        e => e.accessibility && containsAll(filters, e.accessibility || [])
      )
  } else if (tab === 'locations') {
    const filmObj = keyBy(events, 'veeziFilmId')
    const veeziFilmObj = keyBy(groupByLocations[selectedLocation], 'FilmId')
    eventsToShow = Object.keys(veeziFilmObj).map(filmId => {
      return filmObj[filmId]
    }).filter(x => x)
  } else if (tab === 'search') {
    elasticlunr.clearStopWords()
    const store = Index.load(searchIndex)
    if (searchKeyword && searchKeyword.trim() !== '') {
      const search = store
        .search(searchKeyword.trim(), {
          fields: {
            title: { boost: 1, bool: 'AND' },
          },
          expand: true,
        })
        .map(({ ref }) => store.documentStore.getDoc(ref))
        .filter(
          s =>
            s.eventTypes &&
            (s.eventTypes.includes('film') || s.eventTypes.includes('event'))
        )
      eventsToShow = events.filter(
        f => search.findIndex(s => f.slug === s.slug) > -1
      )
    } else {
      eventsToShow = events
    }
  } else {
    eventsToShow = events
  }

  let eventList
  if (tab === 'dates') {
    if (selectedDate === 'all') {
      const weekSessions = veeziWebSessions.filter(s =>
        dayjs(s.PreShowStartTime).isBetween(
          dateRange[0],
          dateRange[1],
          'day',
          '[]'
        )
      )
      eventList = (
        <EventsByDate
          events={eventsToShow}
          veeziWebSessions={weekSessions}
          className={styles.eventList}
        ></EventsByDate>
      )
    } else {
      const eventsWithSessions = eventsToShow.map(e =>
        Object.assign({}, e, {
          sessions: e._sessions.filter(s =>
            dayjs(s.PreShowStartTime).isSame(selectedDate, 'day')
          ),
        })
      )
      eventList = (
        <EventList events={eventsWithSessions} className={styles.eventList} />
      )
    }
  } else {
    eventList = <EventList events={eventsToShow} className={styles.eventList} />
  }

  return (
    <>
    <SEO 
      title={theme.meta_title}
      description={theme.meta_description}
    />
    <div className={`${styles.content} container flex flex-col`}>
      <div className={styles.type}>{THEME_TYPES[theme.type]}</div>
      <h1 className={styles.title}>
        {theme.title}
        {theme.startDate ? (
          <small className={styles.dates}>
            <time dateTime={theme.startDate}>
              {dayjs(theme.startDate).format('D MMM YYYY')}
            </time>
            {' to '}
            <time dateTime={theme.endDate}>
              {dayjs(theme.endDate).format('D MMM YYYY')}
            </time>
          </small>
        ) : null}
      </h1>
      <div
        className={styles.body}
        dangerouslySetInnerHTML={{ __html: theme.html }}
      ></div>
      {slides.length ? (
        <div className={styles.carouselWrapper}>
          <Carousel
            className={styles.carousel}
            slides={slides}
            previewSlides={isMobile}
            isPaused={isSlidePaused}
          />
        </div>
      ) : null}
      <div className={styles.themeBody}>
        <Sponsors sponsors={theme.sponsors} className={styles.sponsors} />
        <Tabs title="Browse by" className={styles.tabs}>
          <Tab
            title="Dates"
            dropdownTitle="Browse By Dates"
            isActive={tab === 'dates'}
            onClick={() => {
              window.location = '#'
              setTab('dates')
            }}
          >
            <DateFilter
              onChange={setSelectedDate}
              onRangeChange={r => setDateRange([dayjs(r[0]), dayjs(r[6])])}
              showHistory={false}
              showAll={true}
              value={selectedDate}
              showCalendarWeeks={true}
              startDate={themeStartDate.current}
              endDate={dayjs(theme.endDate)}
              allowedDates={eventDates.current}
            />
          </Tab>
          <Tab
            title="Accessibility"
            dropdownTitle="Browse By Accessibility"
            isActive={tab === 'accessibility'}
            onClick={() => {
              window.location = '#'
              setTab('accessibility')
            }}
          >
            <TagFilter
              onChange={value =>
                setSelectedAccessibilities(
                  Object.assign({}, selectedAccessibilities, {
                    [value]: !selectedAccessibilities[value],
                  })
                )
              }
            >
              <TagFilterOption
                title="Wheelchair-Friendly"
                value="wheelchair"
                isSelected={selectedAccessibilities['wheelchair']}
              />
              <TagFilterOption
                title="Closed Captions (CC)"
                value="cc"
                isSelected={selectedAccessibilities['cc']}
              />
            </TagFilter>
          </Tab>
          <Tab
            title="Genres"
            dropdownTitle="Browse By Genres"
            isActive={tab === 'categories'}
            onClick={() => {
              window.location = '#'
              setTab('categories')
            }}
          >
            <SelectFilter
              onChange={option => setSelectedCategory(option.value)}
              dropdownTitle="All Genres"
            >
              {categoriesToShow.map((category, i) => (
                <SelectFilterOption
                  key={i}
                  value={category.id}
                  title={category.title}
                  isSelected={selectedCategory === category.id}
                />
              ))}
            </SelectFilter>
          </Tab>
          {theme.slug === '/themes/movies-at-the-cinema/' && <Tab
            title="Locations"
            dropdownTitle="Browse By Locations"
            isActive={tab === 'locations'}
            onClick={() => onTabClick('locations')}
          >
            <SelectFilter
              onChange={option => onLocationClick(option.value)}
              dropdownTitle="All Genres"
              addAll={false}
            >
              <SelectFilterOption
                key='golden-mile-tower'
                value='golden-mile-tower'
                title='Golden Mile Tower'
                isSelected={selectedLocation === 'golden-mile-tower'}
              />
              {/* <SelectFilterOption
                key="riverside-point"
                value="riverside-point"
                title="Riverside Point"
                isSelected={selectedLocation === 'riverside-point'}
              /> */}
              <SelectFilterOption
                key="cineleisure"
                value="cineleisure"
                title="Cineleisure"
                isSelected={selectedLocation === 'cineleisure'}
              />
              {/* <SelectFilterOption
                key="picturehouse-xtra"
                value="picturehouse-xtra"
                title="Picturehouse Xtra"
                isSelected={selectedLocation === 'picturehouse-xtra'}
              /> */}
            </SelectFilter>
          </Tab>}
          <Tab
            title="Search"
            dropdownTitle="Browse By Search"
            titleIcon={searchIcon}
            isActive={tab === 'search'}
            onClick={() => setTab('search')}
            headerClass={styles.search}
          >
            <SearchFilter
              onChange={k => setSearchKeyword(k)}
              onClose={() => setTab('categories')}
            />
          </Tab>
        </Tabs>
        {eventsToShow.length ? (
          eventList
        ) : (
          <div className={styles.noEvents}>No films / events found.</div>
        )}
        <SocialMediaSharing
          title={theme.title}
          slug={theme.slug}
          className={styles.sharing}
        />
      </div>
    </div>
    </>
  )
}

const ThemePage = ({ data, location }) => {
  const theme = {
    html: data.theme.html,
    ...data.theme.fields,
    ...data.theme.frontmatter,
  }

  const events = (data.events.edges || []).map(e => ({
    ...e.node.fields,
    ...e.node.frontmatter,
    categories:
      e.node.fields.categories &&
      e.node.fields.categories.map(c => ({
        ...c.fields,
        ...c.frontmatter,
      })),
  }))

  const categories = data.categories.edges.map(e => ({
    ...e.node.frontmatter,
    ...e.node.fields,
  }))

  const venues = data.venues.edges.map(e => ({
    ...e.node.frontmatter,
    ...e.node.fields,
  }))

  const locations = data.locations.edges.map(e => ({
    ...e.node.frontmatter,
    screens: Array.from(
      new Set(
        flatten(
          (e.node.fields.venues || []).map(v =>
            (v.frontmatter.screens || []).map(s => s.veeziScreenId)
          )
        )
      )
    ),
    venues: (e.node.fields.venues || []).map(e => ({ ...e.frontmatter })),
  }))

  const searchIndex = data.searchIndex && data.searchIndex.index

  return (
    <ThemeTemplate
      theme={theme}
      events={events}
      categories={categories}
      venues={venues}
      location={location}
      locations={locations}
      searchIndex={searchIndex}
    />
  )
}

export default ThemePage

ThemePage.propTypes = {
  data: PropTypes.shape({
    theme: PropTypes.object,
    events: PropTypes.object,
    categories: PropTypes.object,
    locations: PropTypes.object,
    searchIndex: PropTypes.object, 
  }),
}

export const pageQuery = graphql`
  query ThemeBySlug($id: String!, $themeId: String!) {
    theme: markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
     
      frontmatter {
        title
        meta_title
        meta_description
        coverImages {
          image {
            childImageSharp {
              fluid(maxWidth: 1180, maxHeight: 555, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          youtube
        }
        type
        sponsors {
          label
          sponsorType
          group {
            logo {
              childImageSharp {
                fluid(maxWidth: 150, maxHeight: 80, quality: 90, fit: CONTAIN) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            linkURL
          }
        }
        startDate
        endDate
        
      }
    }
    events: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "films-and-events" } }
        frontmatter: { themes: { in: [$themeId] } }
      }
    ) {
      edges {
        node {
          fields {
            slug
            categories {
              frontmatter {
                id
                title
              }
            }
          }
          frontmatter {
            ...EventFrontmatter
          }
        }
      }
    }
    locations: allMarkdownRemark(
      filter: { fields: { collection: { eq: "locations" } } }
      sort: { fields: [frontmatter___title], order: [ASC] }
    ) {
      edges {
        node {
          frontmatter {
            title
            veeziToken
          }
          fields {
            venues {
              frontmatter {
                title
                screens {
                  veeziScreenId
                }
                accessibility
              }
            }
          }
        }
      }
    }
    categories: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "categories" } }
        frontmatter: { appliesTo: { in: ["films"] } }
      }
    ) {
      edges {
        node {
          ...AllCategories
        }
      }
    }
    venues: allMarkdownRemark(
      filter: { fields: { collection: { eq: "venues" } } }
    ) {
      edges {
        node {
          ...AllVenues
        }
      }
    }
    searchIndex: siteSearchIndex {
      index
    }
  }
`
